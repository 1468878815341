// <div class="social-buttons">
//   <a
//     href="#"
//     class="social-button social-button--facebook"
//     aria-label="Facebook"
//   >
//     <i class="fab fa-facebook-f"></i>
//   </a>
//   <a
//     href="#"
//     class="social-button social-button--linkedin"
//     aria-label="LinkedIn"
//   >
//     <i class="fab fa-linkedin-in"></i>
//   </a>
//   <a
//     href="#"
//     class="social-button social-button--snapchat"
//     aria-label="Snapchat"
//   >
//     <i class="fab fa-snapchat-ghost"></i>
//   </a>
//   <a href="#" class="social-button social-button--github" aria-label="GitHub">
//     <i class="fab fa-github"></i>
//   </a>
//   <a href="#" class="social-button social-button--codepen" aria-label="CodePen">
//     <i class="fab fa-codepen"></i>
//   </a>
// </div>;

import React from "react";
import "./Social.css";
import { SvgWrap } from "./SocialSvg";
import data from "../../utils/data";
const Social = ({ dark, contactOnly }) => {
  const socials = contactOnly
    ? data.socials.filter((s) => s?.contact === true)
    : data.socials;
  return (
    <div className={`social-bar ${dark ? "dark-socials bg-zinc-900" : ""}`}>
      <div className="social-icons iconpad text-center">
        {socials.map(({ social, url, targetBlank }) => (
          <a
            className="slider-nav-item"
            target={targetBlank && "_blank"}
            rel="nooppener noreferrer"
            href={url}
            key={social}
          >
            <SvgWrap social={social} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Social;
