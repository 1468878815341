import React from "react";
import ScrollTop from "./ScrollTop";
import constants, { urls } from "../../utils/constants";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";
import { useScrollTrigger } from "../../utils/useScrollPosition";
import NavDrawer from "./NavDrawer";
import "./navbar.css";

const NavLink = ({ path, page: { to, text, title } }) => {
  return (
    <AnchorLink
      to={to}
      title={title}
      className={`nav__link ${path === to ? "font-bold nav__link__hover" : ""}`}
    >
      {text}
    </AnchorLink>
  );
};

const Navbar = ({ path, isBrowser, className, fixed }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleOpenNavMenu = () => {
    setDrawerOpen(true);
  };
  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open ?? !drawerOpen);
  };
  const useScrollNav = useScrollTrigger({
    // disableHysteresis: true,
    threshold: 15,
  });
  const useScrollNavV = !fixed || useScrollNav;
  return (
    <>
      <NavDrawer
        toggleDrawer={toggleDrawer}
        drawerOpen={drawerOpen}
        path={path}
      />
      <nav
        className={`${className ?? ""} px-3 transition-all ${
          useScrollNavV ? "navbg useScrollNav shadow-lg py-3" : "py-7"
        } ${fixed ? "fixed" : "sticky"} top-0 inset-x-0`}
      >
        <div className="max-w-5xl flex flex-wrap items-center relative justify-center mx-auto">
          <div className="flex align-items-center align-content-center">
            <button
              type="button"
              onClick={handleOpenNavMenu}
              className="absolute px-4 inset-y-0 right-3 inline-flex items-center justify-center p-2 text-sm text-white hover:text-grey-600 rounded-lg md:hidden hover:bg-black/[.3] transition-all focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navdrawer"
              aria-expanded={drawerOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div className="w-full">
            <ul
              className={`mx-auto font-medium transition-all duration-500 flex flex-row items-center text-center `}
            >
              <li className="drop-shadow-xl pr-16 pl-5 py-2">
                <AnchorLink to={urls.home}>
                  <StaticImage
                    src="../../images/logo/logo-inline.png"
                    alt="RA"
                    className={`nav-transition ${
                      useScrollNav ? " max-w-[200px]" : "max-w-[250px]"
                    }`}
                    height={110}
                  />
                </AnchorLink>
              </li>
              {constants.navLinks.map((page, index) => (
                <li
                  key={page.to}
                  className={`hidden md:block transition-all duration-500 px-5`}
                >
                  <NavLink path={path} page={page} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
      <ScrollTop />
    </>
  );
};

export default Navbar;
