import React from "react";

import { LongRightArrow, LongLeftArrow } from "../icons";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { urls } from "../../utils/constants";
import ServiceDisplay from "../ServiceDisplay";
const Inner = ({ layoutNum, shortlist, hideAction, useLinks }) => {
  switch (layoutNum) {
    case 1:
      // green background image
      return (
        <>
          <h3 className="my-12 max-w-2xl wow fadeInDown mobileshrink" data-wow-delay=".5s">
            Welcome to <strong className="theme-color">Regalia Stoke</strong>,
            where <strong className="theme-color">culinary excellence</strong>{" "}
            meets{" "}
            <strong className="theme-color">unforgettable experiences</strong>.
            Join us to celebrate life's moments in our elegant venue, offering
            the perfect blend of fine dining and exceptional service.
          </h3>
          {/* <ServiceDisplay large shortlist={shortlist} useLinks={useLinks} /> */}
          {!hideAction && (
            <div
              className="flex w-full space-x-3 mb-8 max-w-lg wow fadeInDown"
              data-wow-delay="1s"
            >
              <AnchorLink to={urls.menu} className="flex-1 button filled green">
                Menu
                <LongRightArrow className="w-6 h-6" />
              </AnchorLink>
            </div>
          )}
        </>
      );
    case 2:
      // black background image
      return (
        <>
          <ServiceDisplay shortlist={shortlist} useLinks={useLinks} />
          <div
            className="flex w-full space-x-3 mb-8 max-w-lg wow bounceInUp"
            data-wow-delay=".5s"
          >
            <AnchorLink to={urls.contact} className="flex-1 button filled">
              Make a Booking
              <LongRightArrow className="w-6 h-6" />
            </AnchorLink>
            <AnchorLink
              to={urls.gallery}
              className="button flex-1 filled green"
            >
              <LongLeftArrow className="w-6 h-6" />
              Gallery
            </AnchorLink>
          </div>
        </>
      );
    // case 3:
    default:
      // red blue background image
      return (
        <div className="flex w-full space-x-3 mb-8 max-w-lg">
          <AnchorLink to={urls.contact} className="button flex-1 filled yellow">
            Get In Touch
            <LongRightArrow className="w-6 h-6" />
          </AnchorLink>
          {/* <AnchorLink to={urls.contact} className="flex-1 button outlined">
            Contact Us
          </AnchorLink> */}
        </div>
      );
  }
};

const IntroContent = ({ layoutNum = 1, ...props }) => {
  return (
    <div className="w-full flex flex-col items-center">
      <Inner layoutNum={layoutNum} {...props} />
    </div>
  );
};

export default IntroContent;
