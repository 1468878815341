import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useBackgroundImage } from "../../utils/queries";
import { Parallax } from "react-scroll-parallax";

const BackgroundImageWrap = ({ children, parallax }) => {
  const className = "absolute w-full top-0 z-0";
  if (!parallax) return <div className={className}>{children}</div>;

  return (
    <Parallax speed={-100} className={className}>
      {children}
    </Parallax>
  );
};
const Section = ({
  title,
  children,
  titleComponent: TitleComponent = "h3",
  bgImage,
  bgImageAlt,
  bgOverlayDarker,
  subtitle,
  navPadding,
  intro,
  introSmall,
  className,
  maxWidth,
  alignLeft,
  titleLeft,
  parallax = true,
  titleClass,
  useDiv,
  customMobileNavPadding,
  itemContainerClassName,
  wowTitleAnimation,
  criticalLoad,
  ...props
}) => {
  const layoutBackgroundImage = useBackgroundImage(bgImage);
  const C = useDiv ? "div" : "section";
  return (
    <C
      className={`relative mx-auto flex flex-col items-center justify-center transition-all ${
        introSmall
          ? `pt-40 md:pt-64 ${customMobileNavPadding ?? "pb-16"}`
          : navPadding
          ? `${customMobileNavPadding ?? "pt-36"} md:pb-16 md:pt-32`
          : children
          ? "py-12"
          : "pt-6 pb-4"
      } ${
        layoutBackgroundImage
          ? "use_bg_image shadow-inner z-20 overflow-hidden"
          : "bg-white z-30"
      } ${
        intro ? "min-h-[95vh] h-[auto] md:min-h-[100vh]" : introSmall ? "" : ""
      } ${className ?? ""}`}
      {...props}
    >
      <div
        className={`z-20 flex flex-col items-center ${
          alignLeft ? "text-left" : "text-center"
        } px-4 ${maxWidth ? "max-w-3xl" : ""} ${navPadding ? "h-full" : ""} ${
          bgImage ? "text-white" : ""
        } ${itemContainerClassName ? itemContainerClassName : ""}`}
      >
        {title && (
          <TitleComponent
            className={`title capitalize z-50 ${titleClass ?? ""} ${
              titleLeft ? "text-left" : "text-center"
            } ${layoutBackgroundImage ? "" : "bg-white"}${
              wowTitleAnimation ?? "wow fadeInDown"
            }`}
          >
            {title}
          </TitleComponent>
        )}
        {subtitle && (
          <h6 className="pb-6 wow fadeInDown" data-wow-delay=".25s">
            {subtitle}
          </h6>
        )}
        {children}
      </div>
      {layoutBackgroundImage && (
        <>
          <BackgroundImageWrap>
            <GatsbyImage
              image={layoutBackgroundImage}
              alt={bgImageAlt ?? title}
              className="h-full"
              // style={{ position: absolute }}
              // critical={criticalLoad}
            />
            <GatsbyImage
              image={layoutBackgroundImage}
              alt={bgImageAlt ?? title}
              className="h-full -scale-y-100 -mt-1 -mb-1"
              // critical={criticalLoad}
              // style={{ position: absolute }}
            />
            <GatsbyImage
              image={layoutBackgroundImage}
              alt={bgImageAlt ?? title}
              className="h-full"
              // critical={criticalLoad}
              // style={{ position: absolute }}
            />
          </BackgroundImageWrap>
          <div
            className={`h-full w-full absolute top-0 ${
              bgOverlayDarker ? "bg-black/[.6]" : "bg-black/[.4]"
            }`}
            //  max-h-[100vh]
          ></div>
        </>
      )}
    </C>
  );
};

export default Section;
