import React from "react";
import Navbar from "../nav/Navbar";
import Section from "./Section";
import { useLogo } from "../../utils/queries";
import { GatsbyImage } from "gatsby-plugin-image";
import data from "../../utils/data";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import constants, { urls } from "../../utils/constants";
import { PhoneIcon, MailIcon, LocationIcon } from "../icons";
import Carousel from "../Carousel";
import Social from "./Social";
const Layout = ({ children, location, path, ssr = false }) => {
  const [page, setPage] = React.useState(null);
  const isRootPath = location.pathname === `${__PATH_PREFIX__}/`;
  React.useEffect(() => {
    // const pa =
    //   path.length > 1 && path.slice(-1) === "/" ? path.slice(0, -1) : path
    setPage(path ? constants.seo[path] : null);
  }, [path]);
  const footerLogo = useLogo("logo-tp");
  const fixedNav = isRootPath || page?.layoutImage;
  return (
    <>
      <a
        href="#main-content"
        className="absolute invisible focus:visible -translate-y-[120%] focus:translate-y-0 no-hover"
        style={{
          transition: "all .3s",
          border: "1px solid #09f",
          top: 5,
          left: 5,
          backgroundColor: "#fff",
          borderRadius: 5,
          zIndex: 9999,
          padding: 3,
        }}
        title="Skip Navigation"
      >
        Skip Navigation
      </a>
      <Navbar
        path={path}
        isBrowser={!ssr}
        className={`z-[60]`}
        fixed={fixedNav}
      />
      <main id="main-content z-30">
        {page &&
          (page.carousel ? (
            <Carousel
              images={page.carousel.map((caro) => (
                <Section
                  navPadding
                  intro
                  useDiv
                  titleComponent="h2"
                  key={page.title}
                  {...caro}
                />
              ))}
            />
          ) : (
            <Section
              bgImage={page.layoutImage}
              bgImageAlt={page.layoutImageAlt ?? page.layoutImage}
              bgOverlayDarker={!fixedNav}
              title={page.displayTitle}
              titleComponent="h2"
              subtitle={page.displaySubtitle}
              navPadding={fixedNav}
              intro={isRootPath}
              introSmall={fixedNav}
              {...page.sectionProps}
            >
              {page.displayComponent && (
                <div className="py-4 w-full">
                  <page.displayComponent {...page.displayComponentProps} />
                </div>
              )}
            </Section>
          ))}
        {children}
      </main>
      <footer className="w-full bg-zinc-900 text-white">
        <div className="max-w-5xl mx-auto px-5">
          <div className="pb-4 border-b-2 border-zinc-600">
            <div className="flex w-100 justify-center flex-col md:flex-row pb-4 pt-6">
              <div className="md:w-1/3 mb-8">
                <h5 className="mb-3">About Us</h5>
                <p className="text-white">
                  Thank you for visiting{" "}
                  <strong className="theme-color">Regalia Stoke</strong>. Stay
                  connected with us through our{" "}
                  <strong className="theme-color">social media</strong> channels
                  and subscribe to our{" "}
                  <strong className="theme-color">newsletter</strong> for the
                  latest updates on events, special offers, and culinary
                  delights. <br />
                  We look forward to welcoming you soon!
                </p>
              </div>

              <div className="md:ml-6 md:w-1/3 flex h-full md:pl-8 flex-col mb-8">
                <h5>Reach Us</h5>
                <a
                  className="flex mb-5 no-underline"
                  href={`tel:${data.contactDetails.number}`}
                  rel="nooppener noreferrer"
                >
                  <PhoneIcon className="mr-5 stroke-theme-color " />
                  {data.contactDetails.numberDisplay}
                </a>
                <a
                  className="flex mb-5 no-underline"
                  href={`mailto:${data.contactDetails.email}`}
                >
                  <MailIcon className="mr-5 stroke-theme-color " />
                  {data.contactDetails.email}
                </a>
                <a
                  className="flex mb-5 no-underline"
                  href={data.contactDetails.locationLink}
                  target="_blank"
                  rel="nooppener noreferrer"
                >
                  <LocationIcon className="mr-5 stroke-theme-color " />
                  {data.contactDetails.address.name}
                  <br />
                  {data.contactDetails.address.street}
                  <br />
                  {data.contactDetails.address.city},{" "}
                  {data.contactDetails.address.postcode}
                </a>
              </div>
              {footerLogo && (
                <div className="md:w-1/3 flex flex-col md:flex-row justify-center items-center mb-4">
                  <AnchorLink to={urls.home}>
                    <GatsbyImage
                      image={footerLogo}
                      alt="Forwardsafe Designs"
                      className="w-full h-full max-h-32"
                      objectFit="contain"
                    />
                  </AnchorLink>
                </div>
              )}
            </div>
            <Social dark />
          </div>
          <div className="flex flex-row p-4 justify-center items-center w-full">
            <p className="text-xs text-center text-zinc-400 ">
              Regalia © 2024 | Designed by{" "}
              <a
                href="https://designs.forwardsafe.co.uk"
                target="_blank"
                rel="noreferrer"
              >
                Forwardsafe Designs
              </a>
            </p>
          </div>
        </div>
      </footer>
      <div
        style={{ position: "absolute", top: 0 }}
        id={constants.topAnchorId}
      ></div>
    </>
  );
};

export default Layout;
