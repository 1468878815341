import React from "react";
import constants from "../../utils/constants";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";
import { urls } from "../../utils/constants";

const NavDrawer = ({ toggleDrawer, drawerOpen, navMobileBreakPoint, path }) => {
  return (
    <div
      className={`fixed bottom-0 left-0 md:hidden top-0 z-[1045] flex w-full h-full ${
        drawerOpen ? "" : `invisible  delay-500`
      }`}
      tabIndex="-1"
      id="smfsNav"
      aria-labelledby="navdrawer"
      role="navigation"
    >
      <div
        className={`offcanvas max-w-[75%] z-50 ${
          drawerOpen ? "" : "-translate-x-full"
        } flex-col border-none navbg bg-clip-padding shadow-sm outline-none transition duration-500 ease-in-out`}
      >
        <div className="flex items-center justify-between p-4">
          <div className="px-5 pt-1">
            <AnchorLink to={urls.home} onAnchorLinkClick={toggleDrawer(false)}>
              <StaticImage
                src="../../images/logo/logo-inline.png"
                alt="Regalia"
                height={60}
              />
            </AnchorLink>
          </div>
          <button
            type="button"
            className="box-content rounded-none border-none opacity-50 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none z-50"
            onClick={toggleDrawer(false)}
          >
            <span className="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#fff"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
        <div className="flex-grow overflow-y-auto pt-4">
          <ul>
            {constants.navDrawerLinks.map((page) => (
              <li key={page.to} className="pl-5 py-2">
                <AnchorLink
                  to={page.to}
                  onAnchorLinkClick={toggleDrawer(false)}
                  className={`no-style no-hover text-lg nav__link ${
                    path === page.to ? "font-bold nav__link__hover" : ""
                  }`}
                >
                  {page.title}
                </AnchorLink>
              </li>
            ))}
            <li></li>
          </ul>
        </div>
      </div>
      <div
        className={`bg-black/[.3] h-full transition-all duration-300 z-40 w-full absolute top-0 left-0 ${
          drawerOpen ? "" : "opacity-0 invisible"
        }`}
        onClick={toggleDrawer(false)}
      ></div>
    </div>
  );
};

export default NavDrawer;
