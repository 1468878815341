import React from "react";
import constants from "../../utils/constants";
import { useScrollTrigger } from "../../utils/useScrollPosition";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useLocation } from "@reach/router";

const ScrollTop = () => {
  const { pathname } = useLocation();
  const showScrollTop = useScrollTrigger({
    // disableHysteresis: true,
    threshold: 100,
  });

  const anchorLink = constants.genTopAnchorLink();
  return (
    <div
      className={`fixed bottom-5 right-5 z-40 transition-scale duration-150 ${
        showScrollTop ? "visible scale-1" : "scale-0 invisible"
      }`}
      role="presentation"
    >
      <AnchorLink to={`${pathname}${anchorLink}`} title="Back to Top">
        <span
          className="block rounded-full bg-green-500 p-2 h-12 w-12 drop-shadow-xl"
          aria-label="Scroll back to top"
        >
          <ChevronUpIcon className="text-white" />
        </span>
      </AnchorLink>
    </div>
  );
};

export default ScrollTop;
