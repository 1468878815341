import React from "react";

const contactDetails = {
  name: "Forwardsafe Designs",
  number: "+441782420715",
  //   whatsappNumber: "",
  useWhatsapp: false,
  usePhone: true,
  numberDisplay: "01782 420715",
  email: "customerservice@regaliastoke.co.uk",
  address: {
    name: "Regalia",
    street: "38 Trinity St",
    city: "Hanley",
    postcode: "ST1 5LJ",
  },
  locationLink:
    "https://www.google.com/maps?gs_lcrp=EgZjaHJvbWUyDggAEEUYJxg5GIAEGIoFMg0IARAuGK8BGMcBGIAEMgcIAhAAGIAEMg0IAxAAGIMBGLEDGIAEMgoIBBAAGLEDGIAEMgYIBRBFGDwyBggGEEUYPTIGCAcQRRg80gEIMTQzM2owajeoAgCwAgA&um=1&ie=UTF-8&fb=1&gl=uk&sa=X&geocode=KXc_5DJ2aXpIMVM-F40WqPnx&daddr=38+Trinity+St,+Hanley,+Stoke-on-Trent+ST1+5LJ",
};
const data = {
  long: {
    aboutUs: ``,
  },
  short: {
    // Who are we
    aboutUs: <></>,
  },
  testimonials: [],
  homeGalleryImages: [
    { imgFileName: "entrance-square", title: "Entrance" },
    { imgFileName: "greendrink-square", title: "Drink" },
  ],
  galleryImages: [
    { imgFileName: "entrance-square", title: "Entrance" },
    { imgFileName: "greendrink-square", title: "Drink" },
    { imgFileName: "front", title: "Outside" },
    { imgFileName: "front_desk", title: "Front Desk" },
    { imgFileName: "tawaw-1", title: "Tawa" },
  ],
  featuredMenuItems: [
    {
      imgFileName: "tawa",
      textTitle: "Tawa's",
      text: (
        <>
          Savor the <strong className="theme-color">sizzling flavors</strong> of
          our Tawa's, a perfect blend of spices and succulent meats, cooked to
          perfection on a traditional tawa.
        </>
      ),
    },
    {
      imgFileName: "shepards-pie",
      textTitle: "Shepard's Pie",
      text: (
        <>
          Dive into our classic Shepherd's Pie, featuring a{" "}
          <strong className="theme-color">hearty mix</strong> of ground lamb and
          vegetables, topped with creamy mashed potatoes.
        </>
      ),
    },
    {
      imgFileName: "lasagna",
      textTitle: "Lasagna",
      text: (
        <>
          Indulge in layers of pasta, rich meat sauce, and{" "}
          <strong className="theme-color">creamy béchamel</strong> in our
          homemade Lasagna, a true comfort food favorite.
        </>
      ),
    },
  ],

  services: [
    {
      name: "Authentic Pakistani Cuisine",
      description:
        "Experience the rich flavors and traditional recipes of Pakistan.",
    },
    {
      name: "Event Bookings Available",
      description:
        "Host your special occasions in our elegant venue, perfect for weddings, parties, and corporate events.",
    },
    {
      name: "Freshly Made Food",
      description: "Enjoy dishes prepared daily with the freshest ingredients.",
    },
    {
      name: "Elegant Venue",
      description:
        "Our beautifully decorated space offers a warm and inviting atmosphere.",
    },
    {
      name: "Locally Sourced Ingredients",
      description:
        "We take pride in using high-quality, locally sourced ingredients in all our dishes.",
    },
    {
      name: "Exceptional Service",
      description:
        "Our dedicated team is committed to providing top-notch service to enhance your dining experience.",
    },
    {
      name: "Family-Friendly Atmosphere",
      description:
        "Bring the whole family and enjoy a comfortable, welcoming environment.",
    },
    {
      name: "Corporate Events",
      description:
        "Book our venue for your next corporate meeting or event and impress your colleagues.",
    },
    // {name:}
  ],
  contactDetails: contactDetails,
  socials: [
    {
      social: "facebook",
      url: "https://www.facebook.com/regaliastoke",
      targetBlank: true,
      contact: true,
    },
    {
      social: "instagram",
      url: "https://www.instagram.com/regaliastoke/",
      targetBlank: true,
      contact: true,
    },
    {
      social: "tiktok",
      url: "https://www.tiktok.com/@regalia_stoke",
      targetBlank: true,
      contact: true,
    },

    {
      social: "snapchat",
      url: "https://snapchat.com/t/uZ1sBMSM",
      targetBlank: true,
      contact: true,
    },
    // { social: "pinterest", url: "", targetBlank: true },
    // { social: "fb_messenger", url: "", targetBlank: true },
    // { social: "skype", url: "", targetBlank: true },
    // { social: "youtube", url: "", targetBlank: true },
    // { social: "meta", url: "", targetBlank: true },
    // {
    //   social: "whatsapp",
    //   url: `https://wa.me/${contactDetails.number}`,
    //   targetBlank: true,
    //   contact: true,
    // },
    // { social: "google_plus", url: "", targetBlank: true },
  ],
};

const getFeaturedItems = (items) =>
  items ? items.filter((i) => i.featured === true) : [];

const addServiceToItem = (service) => (item) => ({ ...item, service: service });

const getFeaturedPortfolio = (portfolios) =>
  portfolios.reduce((accumulator, { items, subTypes, ...service }) => {
    let vals = [];
    if (items && items?.length > 0) {
      vals = getFeaturedItems(items).map(addServiceToItem(service));
    }
    if (subTypes && subTypes.length > 0) {
      vals = [
        ...vals,
        ...subTypes.map(({ items, ...service1 }) =>
          getFeaturedItems(items).map(addServiceToItem(service1))
        ),
      ];
    }
    return [...accumulator, ...vals];
  }, []);

// export const featuredPortfolios = getFeaturedPortfolio(data.portfolio).flat();
export default data;
