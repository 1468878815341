// import { GatsbyImage } from "gatsby-plugin-image"
import React from "react";
import { ChevronLeft, ChevronRight } from "./icons";
import { useInterval } from "../utils/useInterval";
import useInViewport from "../utils/useInViewport";

const SliderControl = ({ onClick, isLeft, disabled }) => (
  <button
    type="button"
    onClick={disabled ? null : onClick}
    disabled={disabled}
    className={`absolute text-white text-2xl  bg-black h-10 w-10 rounded-full opacity-40 hover:opacity-25 flex items-center justify-center top-[50%] z-[50] ${
      isLeft ? "left-6" : "right-6"
    }`}
  >
    <span role="img" aria-label={`Arrow ${isLeft ? "left" : "right"}`}>
      {/* {isLeft ? "◀" : "▶"} */}
      {isLeft ? <ChevronLeft /> : <ChevronRight />}
    </span>
  </button>
);

const Carousel = ({ images }) => {
  const [currentImage, setCurrentImage] = React.useState(0);
  const [isCarouselHover, setIsCarouselHover] = React.useState(false);
  const carouselRef = React.useRef(null);
  const carouselInView = useInViewport(carouselRef);
  const refs = images.reduce((acc, val, i) => {
    acc[i] = React.createRef();
    return acc;
  }, {});
  const onClick = (previous) => () => {
    let i = previous ? currentImage - 1 : currentImage + 1;
    if (i > images.length - 1 || i < 0) {
      i = 0;
    }
    if (i === null) {
      return null;
    }
    setCurrentImage(i);
  };
  useInterval(
    () => onClick()(),
    isCarouselHover || !carouselInView ? null : 5000
  );

  return (
    <div
      onMouseEnter={() => setIsCarouselHover(true)}
      onMouseLeave={() => setIsCarouselHover(false)}
      className="flex justify-center"
      // style={{ height: "100vh" }}
      ref={carouselRef}
    >
      <div className="relative w-full">
        <div
          className="carousel flex flex-row overflow-hidden"
          // onTouchStart={touchStartEvent =>
          //   this.handleTouchStart(touchStartEvent)
          // }
          // onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
          // onTouchEnd={() => onTouchEnd()}
          // ref={carouselInnerRef}
        >
          <SliderControl isLeft onClick={onClick(true)} />
          <div
            className="carousel-inner flex flex-row transition-all duration-700"
            style={{
              // transform: `translateX(-${currentImage * 100}vw) translateZ(1px)`,
              // WebkitTransform: `translateX(-${
              //   currentImage * 100
              // }vw) translateZ(1px)`,
              // transform: `translateX(-${activeTranslate}vw)`,
              // marginLeft: `-${currentImage * 100}vw`,
              marginLeft: `-${currentImage * 100}vw`,
            }}
          >
            {images.map((img, i) => (
              <div
                className="w-[100vw] flex-shrink-0 z-30"
                key={i}
                ref={refs[i]}
              >
                <div className="w-full object-contain">{img}</div>
              </div>
            ))}
          </div>
          <SliderControl onClick={onClick()} />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
