import { ParallaxProvider } from "react-scroll-parallax";
import React from "react";
import "./src/styles/global.css";
import "./src/styles/animation.css";
import "./src/styles/button.css";
import Layout from "./src/components/layout/Layout";
import "./src/components/wow";

export const wrapPageElement = ({ element, props }) => {
  return (
    <ParallaxProvider>
      <Layout {...props}>{element}</Layout>
    </ParallaxProvider>
  );
};
